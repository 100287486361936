export default {
  insuranceOfflineTitle: {
    id: 'insurance_offline_banner_info_title',
    defaultMessage: 'oops',
  },
  insuranceOfflineContent: {
    id: 'insurance_offline_banner_info_description',
    defaultMessage: 'One insurance offer has been put offline',
  },
  noStockTitle: {
    id: 'no_stock_banner_info_title',
    defaultMessage: 'oops',
  },
  noStockDescription: {
    id: 'no_stock_banner_info_description',
    defaultMessage: 'One insurance offer has been put offline',
  },
}
